import { Fragment } from 'react';
import { Box, FormControl, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Photo = ({ bookingData, setBookingData }) => {
  const onChangeImage = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setBookingData((prevState) => ({
          ...prevState,
          photo: reader.result,
        }));
      });
      reader.readAsDataURL(event.target.files[0]);

      setBookingData((prevState) => ({
        ...prevState,
        image: event.target.files[0],
      }));
      console.log(event.target.files[0]);
    }
  };

  const removePhoto = () =>
    setBookingData((prevState) => ({
      ...prevState,
      photo: '',
      image: null,
    }));

  return (
    <Fragment>
      <fieldset className="step-wrapper">
        {!bookingData.photo && (
          <FormControl fullWidth>
            <Button
              className="upload-btn"
              variant="text"
              component="label"
              endIcon={<CloudUploadIcon />}
              sx={{ color: '#BDBDBD', border: '1px solid #bdbdbd', borderRadius: '15px', p: 4, fontWeight: 'bold' }}
            >
              Upload Photo
              <input hidden accept="image/*, .HEIC" type="file" name="photo" onChange={onChangeImage} />
            </Button>
          </FormControl>
        )}

        {bookingData.photo && (
          <Box className="photo-wrapper" fullwidth>
            <img src={bookingData.photo} alt="Preview" />
          </Box>
        )}
      </fieldset>

      {bookingData.photo && (
        <Button variant="text" onClick={removePhoto} fullWidth sx={{ color: '#070707', mt: 2 }}>
          Remove Photo
        </Button>
      )}
    </Fragment>
  );
};

export default Photo;
