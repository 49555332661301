import { useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { AppContext } from '../AppContext';

import './thanks.scss';

const ThankYou = () => {
  const { location: currentLocation } = useParams();

  const {
    context: {
      areas,
      settings: {
        thank_you: { title, subtitle, text },
      },
    },
  } = useContext(AppContext);
  const { home } = areas.find(({ slug }) => slug === currentLocation);

  return (
    <div className="thanks-wrapper">
      <div className="container">
        <div className="section-title">
          <h2>{title}</h2>
        </div>

        <p className="subtitle">{subtitle}</p>
        <p>{text}</p>

        <NavLink to={home} className="red-button">
          Back To Home Page
        </NavLink>
      </div>
    </div>
  );
};

export default ThankYou;
