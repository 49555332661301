import { TextField, Select, InputLabel, MenuItem, FormControl } from '@mui/material';

import GoogleMaps from '../../Autocomplete/Autocomplete';

const Details = ({ bookingData, setBookingData }) => {
  const phonePattern = '\\(?[0-9]{3}\\)?\\s?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}';

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setBookingData((prevState) => ({
      ...prevState,
      bookingDetails: {
        ...prevState.bookingDetails,
        [key]: val,
      },
    }));
  };

  const onAddressChange = (value) =>
    setBookingData((prevState) => ({
      ...prevState,
      bookingDetails: {
        ...prevState.bookingDetails,
        address: value?.description,
      },
    }));

  return (
    <fieldset className="step-wrapper inputs-wrapper">
      <TextField
        required
        fullWidth
        variant="standard"
        label="Name"
        name="customer_name"
        value={bookingData?.bookingDetails?.customer_name || ''}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        fullWidth
        type="email"
        variant="standard"
        label="Email"
        name="email"
        value={bookingData?.bookingDetails?.email || ''}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        fullWidth
        type="tel"
        variant="standard"
        label="Phone"
        name="phone"
        placeholder="(xxx) xxx-xx-xx"
        inputProps={{ pattern: phonePattern }}
        value={bookingData?.bookingDetails?.phone || ''}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <GoogleMaps onAddressChange={onAddressChange} />
      <TextField
        fullWidth
        variant="standard"
        label="Address 2"
        name="unit"
        value={bookingData?.bookingDetails?.unit || ''}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        fullWidth
        type="number"
        variant="standard"
        label="Zip Code"
        name="zip"
        value={bookingData?.bookingDetails?.zip || ''}
        onWheel={(event) => event.target.blur()}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
        <InputLabel id="available_offers">Available Offers</InputLabel>
        <Select
          labelId="available_offers"
          name="available_offers"
          onChange={handleChange}
          label="Type Of Service"
          value={bookingData?.bookingDetails?.available_offers || ''}
        >
          <MenuItem value="veterans">$25 off - Veterans</MenuItem>
          <MenuItem value="seniors">$25 off - Seniors</MenuItem>
          <MenuItem value="call">$0 - Free Service Call (with repair)</MenuItem>
        </Select>
      </FormControl>
      <TextField
        required
        fullWidth
        multiline
        rows={4}
        variant="standard"
        label="Message"
        name="message"
        value={bookingData?.bookingDetails?.message || ''}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <p className="note">* By booking a job, you agree to receive SMSs and emails regarding the booking requested</p>
      <p className="note">Note: All fields marked with an asterisk (*) are required </p>
      <p className="data-protection">
        <Lock /> Data protected and privacy
      </p>
    </fieldset>
  );
};

export default Details;

const Lock = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="LockIcon"
  >
    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
  </svg>
);
