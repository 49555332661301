import { ToastContainer } from 'react-toastify';

import { Header } from '..';

import './layout.scss';

const Layout = ({ id, children }) => {
  return (
    <div className="layout" id={id}>
      <Header />
      <main>{children}</main>
      <ToastContainer />
    </div>
  );
};

export default Layout;
