import { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { AppContext } from './AppContext';

import Preloader from './Preloader/Preloader';
import Booking from '../pages/Booking';
import ThankYouPage from '../pages/ThankYouPage';
import NotFound from '../pages/NotFound';

const AppRouter = () => {
  const {
    context: { areas, settings },
  } = useContext(AppContext);

  if (!areas || !settings) return <Preloader />;

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Navigate replace to={areas?.[0]?.slug || '/404'} />} />

        <Route path={':location'}>
          <Route index element={<Booking />} />
          <Route path="thank-you" element={<ThankYouPage />} />
        </Route>

        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
