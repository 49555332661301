import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

import { AppContext } from '../components/AppContext';
import { Layout, Accordion, Form } from '../components';
const { REACT_APP_DOMAIN: DOMAIN } = process.env;

const getContactForm = (id) => `https://${DOMAIN}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`;

function capitalize(string) {
  return `${string.at(0).toLocaleUpperCase()}${string.slice(1).toLocaleLowerCase()}`;
}

const Booking = () => {
  const { source: currentSource } = useSearchParams();
  const { location: currentLocation } = useParams();
  const navigate = useNavigate();

  const {
    context: {
      areas,
      settings: { faq, logo },
    },
  } = useContext(AppContext);
  const { city, form, source, pipedriveId } = areas.find(({ slug }) => slug === currentLocation);

  const getFormDefaults = () => {
    return { _wpcf7_unit_tag: true, job_source: capitalize(currentSource ?? source), city, pipedrive_id: pipedriveId };
  };

  const [isPending, setRequestStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [bookingData, setBookingData] = useState({
    bookingDate: '',
    bookingTime: '',
    bookingDetails: getFormDefaults(),
  });
  const [stepTitle, setStepTitle] = useState('Choose a service');

  const reset = () =>
    setBookingData({
      bookingDate: '',
      bookingTime: '',
      bookingDetails: getFormDefaults(),
    });

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append('date', moment(bookingData?.bookingDate)?.format('LL'));
    formData.append('time', bookingData?.bookingTime);
    formData.append('image', bookingData?.image);
    Object.entries(bookingData.bookingDetails).forEach(([key, value]) => formData.append(key, value));

    try {
      setRequestStatus(true);
      const response = await axios.post(getContactForm(form), formData);
      setRequestStatus(false);

      if (response.status === 200) {
        event.target.reset();
        setActiveStep(0);
        navigate('./thank-you');
      }
    } catch (error) {
      console.log(error);
      return toast.error('Ooops! Something went wrong! Please, try again!');
    }
  };

  useEffect(() => {
    if (activeStep === 0) reset();

    switch (activeStep) {
      case 1:
        setStepTitle('Choose a brand');
        break;
      case 2:
        setStepTitle('Upload photo (not required)');
        break;
      case 3:
        setStepTitle('Choose a day');
        break;
      case 4:
        setStepTitle('Choose a time');
        break;
      case 5:
        setStepTitle('Details');
        break;
      case 6:
        setStepTitle('Order Summary');
        break;
      default:
        setStepTitle('Choose a service');
        break;
    }
  }, [activeStep]);

  return (
    <Layout id="booking-page" step={activeStep}>
      <section className="booking__wrapper">
        <div className="container">
          <div className="booking__inner">
            <div className="faq">
              <h1>{stepTitle}</h1>

              <div>
                {faq.map(({ question, answer }, index) => (
                  <Accordion title={question} key={index}>
                    {answer}
                  </Accordion>
                ))}

                <div
                  className="logo"
                  style={{
                    backgroundImage: `url("${logo}")`,
                  }}
                />
              </div>
            </div>

            <div className="form__wrapper">
              <Form
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                stepTitle={stepTitle}
                bookingData={bookingData}
                setBookingData={setBookingData}
                onSubmit={onSubmit}
                isPending={isPending}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Booking;
