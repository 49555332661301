import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AppContext } from '../AppContext';

import './header.scss';

const Header = () => {
  const { location: currentLocation } = useParams();

  const {
    context: { areas },
  } = useContext(AppContext);
  const { phone, home } = areas.find(({ slug }) => slug === currentLocation);

  return home || phone ? (
    <header className="page-header">
      <div className="container">
        <div className="page-header__inner">
          {home ? <a href={home}>Visit Website</a> : <span></span>}

          {phone && (
            <a href={`tel:+1${phone}`}>
              <span />
              {phone}
            </a>
          )}
        </div>
      </div>
    </header>
  ) : (
    <></>
  );
};

export default Header;
