import AppContextProvider from './AppContext';
import AppRouter from './AppRouter';

const App = () => (
  <AppContextProvider>
    <AppRouter />
  </AppContextProvider>
);

export default App;
