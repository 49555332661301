import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import { AppContext } from '../../AppContext';
import { Spinner } from '../..';

const {
  REACT_APP_PIPEDRIVE_START_TIME_FIELD: START_TIME_FIELD,
  REACT_APP_PIPEDRIVE_LOCATION_FIELD: LOCATION_FIELD,
  REACT_APP_PIPEDRIVE_TIME_FIELD: TIME_FIELD,
  REACT_APP_PIPEDRIVE_TOKEN: TOKEN,
  REACT_APP_PIPEDRIVE_SUBDOMAIN: SUBDOMAIN,
} = process.env;

const Time = ({ bookingData, setBookingData, date }) => {
  const { location: currentLocation } = useParams();
  const {
    context: { areas },
  } = useContext(AppContext);
  const { slots, pipedriveId } = areas.find(({ slug }) => slug === currentLocation);

  const URL = `https://${SUBDOMAIN}.pipedrive.com/api/v1/deals/timeline?start_date=${
    date.split('T')[0]
  }&interval=day&amount=1&field_key=${START_TIME_FIELD}&api_token=${TOKEN}`;

  const [deals, setDeals] = useState();
  const [availableSlots, setAvailableSlots] = useState();

  const handleChange = (val, time) => {
    setBookingData((prevState) => ({
      ...prevState,
      bookingTime: val,
      time,
    }));
  };

  const getDateData = async () => {
    try {
      const response = await axios.get(URL);

      if (response.status === 200) {
        setDeals(response?.data?.data?.[0]?.dels ?? []);
      }
    } catch (error) {
      console.error(error);
      return toast.error(error);
    }
  };

  useEffect(() => {
    getDateData();
  }, []);

  useEffect(() => {
    if (deals) {
      let slotsData = [];

      for (const slot in slots) {
        const { available, label, max } = slots[slot];
        const dealsWithSlot =
          deals.filter((deal) => deal[TIME_FIELD] === slot && deal[LOCATION_FIELD] === pipedriveId && available) ?? [];

        if (!(dealsWithSlot.length >= max))
          slotsData.push({
            slot,
            label,
          });
      }

      if (new Date(date).getDate() === new Date().getDate()) {
        slotsData = slotsData.filter(({ slot }) => slot / 4 - 85 > new Date().getHours());
      }

      setAvailableSlots(slotsData);
    }
  }, [deals]);

  if (!deals || !availableSlots) return <Spinner />;

  return (
    <fieldset className="step-wrapper">
      {availableSlots?.map(({ label, slot }, index) => (
        <div
          className={`slot-wrapper ${bookingData.time === label ? 'active' : 'inactive'}`}
          key={index}
          onClick={() => handleChange(slot, label)}
        >
          <span>{label}</span>
        </div>
      ))}

      {!availableSlots.length && <p className="no-slots">No slots available, please select another date</p>}
    </fieldset>
  );
};

export default Time;
