import { TextField } from '@mui/material';
import moment from 'moment';

const phonePattern = '\\(?[0-9]{3}\\)?\\s?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}';

const Confirm = ({ bookingData }) => (
  <fieldset>
    <TextField
      value={bookingData.bookingDetails.customer_name}
      disabled
      fullWidth
      variant="standard"
      label="Your name"
      sx={{ mb: 2 }}
    />

    <TextField
      value={bookingData.bookingDetails.email}
      disabled
      fullWidth
      variant="standard"
      label="Email"
      sx={{ mb: 2 }}
    />

    <TextField
      value={bookingData.bookingDetails.phone}
      disabled
      fullWidth
      variant="standard"
      label="Phone"
      inputProps={{ pattern: phonePattern }}
      sx={{ mb: 2 }}
    />

    <TextField
      value={bookingData.bookingDetails.type_of_service}
      disabled
      fullWidth
      variant="standard"
      label="Type of service"
      sx={{ mb: 2 }}
    />

    <TextField
      value={bookingData.bookingDetails.address}
      disabled
      fullWidth
      variant="standard"
      label="Address"
      sx={{ mb: 2 }}
    />

    <TextField
      value={bookingData.bookingDetails.zip}
      disabled
      fullWidth
      variant="standard"
      label="Zip Code"
      sx={{ mb: 2 }}
    />

    <TextField
      value={moment(bookingData.bookingDate).format('MMMM DD YYYY')}
      disabled
      fullWidth
      variant="standard"
      label="Date"
      sx={{ mb: 2 }}
    />

    <TextField value={bookingData.time} disabled fullWidth variant="standard" label="Time" sx={{ mb: 2 }} />
  </fieldset>
);

export default Confirm;
