import { Stepper, Step } from 'react-form-stepper';

import { Spinner } from '..';

import Service from './Steps/Service';
import Brand from './Steps/Brand';
import Photo from './Steps/Photo';
import Day from './Steps/Day';
import Time from './Steps/Time';
import Details from './Steps/Details';
import Confirm from './Steps/Confirm';

import './form.scss';

const Form = ({ activeStep, setActiveStep, stepTitle, bookingData, setBookingData, onSubmit, isPending }) => (
  <div className="form">
    <Stepper activeStep={activeStep} className="stepper">
      <Step onClick={() => setActiveStep(0)} />
      <Step onClick={() => setActiveStep(1)} />
      <Step onClick={() => setActiveStep(2)} />
      <Step onClick={() => setActiveStep(3)} />
      <Step onClick={() => setActiveStep(4)} />
      <Step onClick={() => setActiveStep(5)} />
    </Stepper>

    {isPending && (
      <div className="pending">
        <Spinner />

        <span>Request being processed...</span>
      </div>
    )}

    {!isPending && (
      <form onSubmit={onSubmit}>
        <div className="step-title">{stepTitle}</div>

        {activeStep === 0 && <Service bookingData={bookingData} setBookingData={setBookingData} />}

        {activeStep === 1 && <Brand bookingData={bookingData} setBookingData={setBookingData} />}

        {activeStep === 2 && <Photo bookingData={bookingData} setBookingData={setBookingData} />}

        {activeStep === 3 && <Day bookingData={bookingData} setBookingData={setBookingData} />}

        {activeStep === 4 && (
          <Time bookingData={bookingData} setBookingData={setBookingData} date={bookingData.bookingDate} />
        )}

        {activeStep === 5 && <Details bookingData={bookingData} setBookingData={setBookingData} />}

        {activeStep === 6 && <Confirm bookingData={bookingData} />}

        <div className="form-buttons">
          <div
            className="back-btn"
            onClick={() => setActiveStep(activeStep - 1)}
            disabled={activeStep === 0}
            style={{ opacity: activeStep === 0 ? 0 : 1 }}
          >
            Previous
          </div>

          {activeStep !== 5 && (
            <div
              className="continue-btn"
              onClick={() => setActiveStep(activeStep + 1)}
              disabled={
                (activeStep === 0 && !bookingData.bookingDetails.type_of_service) ||
                (activeStep === 1 && !bookingData.bookingDetails.brand) ||
                (activeStep === 3 && !bookingData.bookingDate) ||
                (activeStep === 4 && !bookingData.time) ||
                (activeStep === 5 &&
                  (!bookingData.bookingDetails.customer_name ||
                    !bookingData.bookingDetails.email ||
                    !bookingData.bookingDetails.phone ||
                    !bookingData.bookingDetails.address ||
                    !bookingData.bookingDetails.zip ||
                    !bookingData.bookingDetails.message))
              }
            >
              {activeStep !== 2 && activeStep !== 6 && 'Continue'}
              {activeStep === 2 && (bookingData.photo ? 'Continue' : 'Skip this step')}
            </div>
          )}

          {activeStep === 5 && <input type="submit" value="Confirm" className="submit-btn" />}
        </div>
      </form>
    )}
  </div>
);

export default Form;
